import React from "react"

import Layout from "../components/layout"
import { Link } from "gatsby"
import SEO from "../components/seo"
import notfound from "../images/404.png"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="not-found">
      <img src={notfound} alt="" />
      <Link to={"/"} className="btn">
        Já takk!
      </Link>
    </div>
  </Layout>
)

export default NotFoundPage
